<template>
  <div class="legal">
    <div class="background"></div>
    <v-container>
      <v-row class="mb-10 justify-center">
        <v-col class="content_style px-10 py-11" cols="12" sm="8">
          <img class="hidden-sm-and-down " src="/static/img/common/legal1.png?v=20221027" alt="">
          <img class="hidden-md-and-up " src="/static/img/common/legal1_mobile.png?v=20221027" alt="">
          <!-- <p class="titleName text-h3 mb-10 text-center titleName">特定商取引法に基づく表記</p>
          <h2>1. 販売事業者</h2>
          <p>Lancelot Tech Ltd.</p>
          <h2>2.代表者</h2>
          <p>代表取締役社長 Gong Chok Yee</p>
          <h2>3.所在地及び電話番号</h2>
          <p>所在地：Vistra Corporate Services, Suite 23, 1st Floor, Eden Plaza, Mahe, 999126, SYC</p>
          <p>電話番号：+852 37515975</p>
          <h2>4.お問い合わせ</h2>
          <p>お問い合わせは riichicitysupport@mahjong-jp.com までお寄せください。</p>
          <p>日本地区カスタマーサービス：050-8883-9659</p>
          <p>受付時間：9:00〜18:00</p>
          <h2>5. 販売価格</h2>
          <p>購入手続きの際、都度画面に表示いたします。</p>
          <h2>6. 代金の支払方法・時期</h2>
          <p>Visa、Master、American Express、Unionpay、Paypal、コンビニ決済、Pay-easy決済（※Pay-easy決済はゆうちょ銀行ATM/ゆうちょ銀行ダイレクト経由でご利用になれません。）など、プラットフォームで設定された支払い方法に基づきます。今後、より多くの支払い方法を追加できるように努めてまいります。</p>
          <h2>7. 販売価格以外にご負担いただく費用</h2>
          <p>アプリをご利用いただくために必要なインターネット接続環境、ソフトウェア、通信料金等はお客様の負担となります。</p>
          <h2>8. 商品等の引渡時期</h2>
          <p>購入確定後、すぐにご利用いただけます。</p>
          <h2>9. 商品引き渡し後の返品（キャンセル）、交換</h2>
          <p>デジタルコンテンツの特性上、購入確定後の返品・交換には応じられません。</p>
          <h2>10. 免責事項</h2>
          <p>システム・ネットワークトラブル、その他の事由によりサービス・商品の提供ができないときは、弊社に重大な過失がある場合を除いて、その責任を一切負わないものとします。</p>
          <h2>11. 対応機種</h2>
          <p>対象のサービスが動作する端末が必要です。動作環境は、各アプリストアにてご確認ください。</p> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.legal{
  position: relative; 
  .background{
    position: fixed;
    display: block;
    top:0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: url('/static/img/common/policyBackground.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
  }
  .content_style{
    z-index: 3;
  }
  img {
    width: 100%;
    height: auto;
  }
  // .titleName {
  //   font-size: 50px;
  //   color: #ffffff; 
  //   font-weight: 900;
  //   text-indent: 0em;
  // }
  // h2 {
  //   color: #ffffff; 
  //   font-weight: 700;
  //   margin: 60px 0 30px 0;
  //   font-size: 32px;
  //   text-indent: 0;
  // }
  // p {
  //   color: #ffffff; 
  //   // text-indent: 1em;
  //   font-weight: 500;
  // }
}
</style>